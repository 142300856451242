<template>
  <div class="nosotros">
    <div class="container-fluid menu_ajust bg-nosotros">
      <!-- ===============
BLOQUE HEADER
=====================  -->
      <section id="inicio" class="container p-4 mt-4 text-left">
        <h1 class="tt-prin">#EVOLUCIONA</h1>
        <h1 class="tt-segun">CON NOSOTROS!</h1>

        <p class="tx-general">
          Sumamos una serie de rica y larga experiencia en el negocio <br />
          hipotecario y financiamiento en Chile.
        </p>

        <p class="tx-general">
          Nuestro objetivo ha sido la conformación de una compañía que
          compita<br />
          por maximizar el volumen a través de la mejor oferta en servicio ,
          con<br />
          una atención y asesoría personalizada, y tecnología e innovación en
          nuestros procesos.
        </p>
      </section>
    </div>

    <div class="container juntar">
      <!-- ===============
      BLOQUE CARD GERENTES
      =====================  -->

      <section>
        <h1 class="tt-gerente">Gerentes</h1>

        <div class="row d-flex justify-content-center">
          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Máximo <br />
                <span class="tx-nombre-celeste">Silva Irarrázaval</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Gerente</strong> <br />
                General
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> msi@hipotecariaevoluciona.cl </span>
                <br />
                <span class="tx-fono1"> +56 2 3324 1110 </span>
                <br />

                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->

          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Vicente <br />
                <span class="tx-nombre-celeste">Padilla Mittermayr</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Gerente</strong> <br />
                Comercial Inmobiliaria
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> vpm@hipotecariaevoluciona.cl </span>
                <br />
                <span class="tx-fono1"> +56 2 3324 1113 </span>
                <br />
                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->

          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Joaquín <br />
                <span class="tx-nombre-celeste">Ureta Robert</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Gerente</strong> <br />
                Comercial Residencial
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> jur@hipotecariaevoluciona.cl </span>
                <br />
                <span class="tx-fono1"> +56 2 3324 1112 </span>
                <br />

                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->
          <div></div>

          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Oriana <br />
                <span class="tx-nombre-celeste">Hidalgo Bustamante</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Gerente</strong> <br />
                Administración y Finanzas
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> ohb@hipotecariaevoluciona.cl </span>
                <br />
                <span class="tx-fono1"> +56 2 3324 1111 </span>

                <br />
                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->

          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Patricio <br />
                <span class="tx-nombre-celeste">Salinas Herrera</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Gerente</strong> <br />
                Riesgo
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> psh@hipotecariaevoluciona.cl </span>
                <br />
                <span class="tx-fono1"> +56 2 3324 1114 </span>

                <br />
                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->
        </div>
      </section>

      <!-- ===============
      BLOQUE CARD TRABAJADORES
      =====================  -->

      <section class="mt-4">
        <br />
        <h1 class="tt-gerente">Atención Personas</h1>
        <br />
        <div class="row d-flex justify-content-center">
          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Francisco <br />
                <span class="tx-nombre-celeste">Cornejo Urzúa</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Ejecutivo</strong> <br />
                Comercial Residencial
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> fcu@hipotecariaevoluciona.cl </span>
                <br />
                <span class="tx-fono1"> +56 2 3324 1125 </span>
                <br />
                <span class="tx-fono2"> +56 9 7549 2234 </span>
                <br />
                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->

          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Sandra <br />
                <span class="tx-nombre-celeste">Cifuentes Muñoz</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Ejecutivo</strong> <br />
                Comercial Residencial
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> scm@hipotecariaevoluciona.cl </span>
                <br />
                <span class="tx-fono1"> +56 2 3324 1123 </span>
                <br />
                <span class="tx-fono2"> +56 9 9331 4181 </span>
                <br />
                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->

          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Patricia <br />
                <span class="tx-nombre-celeste">Garcia Hidalgo</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Ejecutivo</strong> <br />
                Comercial Residencial
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> pgh@hipotecariaevoluciona.cl </span>
                <br />
                <span class="tx-fono1"> +56 2 3324 1126 </span>
                <br />
                <span class="tx-fono2"> +56 9 9877 5907 </span>

                <br />
                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->

          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Orlando <br />
                <span class="tx-nombre-celeste">Hernández Moreno</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Ejecutivo</strong> <br />
                Comercial Residencial
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> ohm@hipotecariaevoluciona.cl </span>
                <br />
                <span class="tx-fono1"> +56 2 3324 1122 </span>
                <br />
                <span class="tx-fono2"> +56 9 7905 5013 </span>

                <br />
                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->
          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Ximena <br />
                <span class="tx-nombre-celeste">Simonet Gallardo</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Ejecutivo</strong> <br />
                Comercial Residencial
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> xsg@hipotecariaevoluciona.cl </span>
                <br />

                <span class="tx-fono2"> +56 9 7846 5276 </span>
                <br />
                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->

          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Andrea<br />
                <span class="tx-nombre-celeste">Pardo Fuenzalida</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Ejecutivo</strong> <br />
                Comercial Residencial
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> apf@hipotecariaevoluciona.cl </span>
                <br />
                <span class="tx-fono1"> +56 2 3324 1137 </span>
                <br />
                <span class="tx-fono2"> +56 9 9435 8889 </span>
                <br />
                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->

          <!-- BOX Empleado  -->
          <div class="col-lg-3">
            <!-- Card Especial Empleado -->
            <figure class="imghvr-fade card-empleado text-center w-100">
              <!-- texto interior -->
              <h1 class="tx-nombre">
                Felipe<br />
                <span class="tx-nombre-celeste">Ramirez Silva</span>
              </h1>
              <img src="img/line.png" alt="linea" class="mt-1" />
              <h2 class="tx-cargo">
                <strong>Ejecutivo</strong> <br />
                Comercial Residencial
              </h2>
              <div class="d-flex justify-content-center">
                <img src="img/phone-em.png" alt="icono telefono" class="m-1" />
                <img src="img/email-em.png" alt=" icono email" class="m-1" />
              </div>
              <!-- texto hover-->
              <figcaption>
                <span class="tx-mail"> frs@hipotecariaevoluciona.cl </span>
                <br />

                <span class="tx-fono2"> +56 9 9632 1392 </span>
                <br />
                <br />
                <span class="ofi"> Nuestras oficinas: </span>
                <br />
                <span class="dire"
                  >Av. Pdte. Riesco 5561, <br />
                  of 1804, Las Condes.
                </span>
              </figcaption>
            </figure>
            <!-- Card Especial Empleado -->
          </div>
          <!-- BOX Empleado  -->
        </div>
      </section>
    </div>
    <!-- Cierre Contenedor  -->
  </div>
</template>

<script>
export default {
  name: 'Nosotros',
};
</script>
