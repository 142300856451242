<template>
  <div class="Home">
    <div class="container menu_ajuste p-4">
      <!-- contenidos  -->
      <div class="row p-4">
        <!-- col 1  -->
        <div class="col-lg-3">
          <!-- card   -->

          <figure class="imghvr-fade3 card-credi text-center w-100">
            <!-- texto interior -->
            <h3 class="tt-credi">
              Crédito Full <br />
              Capital de trabajo
            </h3>

            <div class="d-flex justify-content-center">
              <img
                loading="lazy"
                src="img/icono_credito_full.png"
                class="w-100"
                alt=""
              />
            </div>
            <!-- texto hover-->
            <figcaption>
              <p class="tx-fag">
                Financia tus créditos con garantías inmobiliarias a plazos de
                hasta 30 años, de manera que puedas minimizar tu cuota mensual y
                ese excedente invertirlo en tu negocio.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- card   -->
        </div>
        <!-- col 1  -->

        <!-- col 2  -->
        <div class="col-lg-3 text-center">
          <!-- card   -->

          <figure class="imghvr-fade3 card-credi text-center w-100">
            <!-- texto interior -->
            <h3 class="tt-credi">¡Refinanciamiento Ya!</h3>

            <div class="d-flex justify-content-center">
              <img
                loading="lazy"
                src="img/icono_refinanciamiento_YA.png"
                class="w-100"
                alt=""
              />
            </div>
            <!-- texto hover-->
            <figcaption>
              <p class="tx-fag">
                Reestructura todos tus pasivos con esta alternativa de
                financiamiento y si quieres, pide capital adicional para tus
                proyectos.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- card   -->
        </div>
        <!-- col 2  -->

        <!-- col 3  -->
        <div class="col-lg-3">
          <!-- card   -->

          <figure class="imghvr-fade3 card-credi text-center w-100">
            <!-- texto interior -->
            <h3 class="tt-credi">Leasing Inmobiliario</h3>

            <div class="d-flex justify-content-center">
              <img
                loading="lazy"
                src="img/icono_leasing.png"
                class="w-100"
                alt=""
              />
            </div>
            <!-- texto hover-->
            <figcaption>
              <p class="tx-fag">
                Financiamiento para la adquisición de activos vía contrato de
                arrendamiento con opción de compra al final del contrato.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- card   -->
        </div>
        <!-- col 3  -->

        <!-- col 4  -->
        <div class="col-lg-3">
          <router-link to="/asesoramos-empresas">
            <img
              loading="lazy"
              src="img/contactanos.svg"
              class="float_con"
              alt=""
          /></router-link>
        </div>
        <!-- col 4  -->
      </div>
      <!-- contenidos  -->

      <!-- contenidos 2  -->

      <!-- col 1 -->
      <div class="row">
        <!-- col 1 -->
        <div class="col-lg-3">
          <p class="text-cred-empresa">Financiamos hasta un</p>
          <h1 class="grande-celeste">80 <span>%</span></h1>
          <img loading="lazy" src="img/arrows.svg" class="arrows1" alt="" />
        </div>
        <!-- col 1 -->

        <!-- col 1 -->
        <div class="col-lg-3">
          <p class="text-cred-empresa">Plazo hasta por</p>
          <h1 class="grande-celeste">30</h1>
          <span class="age">Años</span>
          <img loading="lazy" src="img/arrows.svg" class="arrows2" alt="" />
        </div>
        <!-- col 1 -->

        <!-- col 1 -->
        <div class="col-lg-3">
          <p class="text-cred-empresa">Financiamos desde</p>
          <h1 class="grande-celeste"><span class="uf">UF</span>20.000</h1>
        </div>
        <!-- col 1 -->

        <!-- col 1 -->

        <!-- col 1 -->
      </div>

      <!-- contenidos 2  -->
    </div>
    <!-- Cierre Contenedor  -->
  </div>
</template>

<script>
import CommonService from '../../service/CommonService';
import store from '../../store/index';

export default {
  name: 'Home',
  created() {
    CommonService.scrollToTop();
    store.commit('changeCreditType', 'company');
  },
};
</script>
