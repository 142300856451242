<template>
  <div class="home">
    <!--    <a href="#form"
    ><img src="img/simuladores.svg" class="simuladores2" alt=""
    /></a>-->

    <div class="container-fluid menu_ajuste">
      <!-- FORMULARIO SUBSIDIO  -->
      <FormCompleto />
      <!-- FORMULARIO SUBSIDIO  -->

      <div class="row">
        <div class="col-lg-12">
          <!-- CONTENIDO TITULAR -->
          <div class="mt-4">
            <div class="row text-left d-flex justify-content-start">
              <div class="col-lg-2"></div>
              <div class="col-lg-4">
                <h1 class="tt_sub">¡Tu Eliges!</h1>
                <h2 class="st_sub">Crédito depende de ti:</h2>
              </div>
            </div>
          </div>
          <!-- CONTENIDO TITULAR -->
          <!-- CONTENIDO CENTRAL -->
          <div class="col-lg-12">
            <p class="p_sub">
              Elige entre un dividendo pactado o uno convenido.
            </p>
          </div>

          <!-- CARD GRID -->
          <div class="row d-flex justify-content-center">
            <!-- CARD -->
            <div class="col-lg-3">
              <div class="card-sub text-center">
                <h4 class="tt_b">Compra</h4>
                <img src="img/house.png" alt="" />
                <p class="b_body">
                  ¡Adquiere tu propiedad! <br />
                  Primera Vivienda<br />
                  Segunda Vivienda<br />
                  Inversión
                </p>
              </div>
            </div>
            <!-- CARD -->

            <!-- CARD -->
            <div class="col-lg-3">
              <div class="card-sub text-center">
                <h4 class="tt_b">
                  Refinanciamiento <br />
                  <span style="font-weight: 300">+ fines generales</span>
                </h4>
                <img src="img/circulo.png" alt="" />
                <p class="b_body">
                  ¡Refinancia tu crédito hipotecario con nosotros!
                  <strong> y si tu propiedad tiene plusvalía ¡úsala! </strong>
                </p>
              </div>
            </div>
            <!-- CARD -->

            <!-- CARD -->
            <div class="col-lg-3">
              <div class="card-sub text-center">
                <h4 class="tt_b">Libre Disponibilidad $</h4>
                <img src="img/mano.png" alt="" />
                <p class="b_body">
                  ¿Tienes una propiedad <br />
                  sin deuda?
                  <strong>
                    <br />
                    Obtén $ con nosotros <br />
                    para lo que tu quieras.
                  </strong>
                </p>
              </div>
              <p class="text-blue">
                * Consultar por esta alternativa con Ejecutivo.
              </p>
            </div>
            <!-- CARD -->
          </div>
          <!-- CARD GRID -->

          <!-- CARD PLAZOS -->
          <div class="row d-flex justify-content-center p-4">
            <div class="col-lg-3">
              <h5 class="b_plazo">Plazo hasta por</h5>
              <h1 class="b_age">30</h1>
              <h3 class="p_age">Años</h3>
            </div>

            <div class="col-lg-3">
              <h5 class="b_plazo">Financiamos hasta un</h5>
              <h1 class="b_age">80 <span class="d_age">%</span></h1>
              <h3 class="p_age">de tu propiedad</h3>
            </div>
          </div>
          <!-- CARD PLAZOS -->

          <!-- CONTENIDO CENTRAL -->
        </div>
        <!-- Cierre Contenedor  -->
      </div>

      <!-- LINEA -->
      <div class="col-lg-10 p-4">
        <img loading="lazy" src="img/line-e.png" width="50%" alt="" />
      </div>
      <!-- LINEA -->

      <div class="col-lg-12">
        <!-- CONTENIDO TITULAR -->
        <div class="m-4">
          <div class="row text-left d-flex justify-content-center">
            <div class="col-lg-2"></div>
            <div class="col-lg-4">
              <h1 class="tt_sub">Si prefieres lo tradicional</h1>
              <h2 class="st_sub">Crédito hipotecario universal:</h2>
            </div>

            <div class="col-lg-4"></div>
            <div class="col-lg-2"></div>
          </div>
        </div>
        <!-- CONTENIDO TITULAR -->

        <!-- CONTENIDO CENTRAL -->
        <div class="col-lg-12">
          <p class="p_sub">
            Tasa y dividendo fijo por todo el plazo seleccionado.
          </p>
        </div>

        <!-- CARD GRID -->
        <div class="row d-flex justify-content-center">
          <!-- CARD -->
          <div class="col-lg-3">
            <div class="card-sub text-center">
              <h4 class="tt_b">Compra</h4>
              <img src="img/house.png" alt="" />
              <p class="b_body">
                ¡Adquiere tu propiedad! <br />
                Primera Vivienda<br />
                Segunda Vivienda<br />
                Inversión
              </p>
            </div>
          </div>
          <!-- CARD -->

          <!-- CARD -->
          <div class="col-lg-3">
            <div class="card-sub text-center">
              <h4 class="tt_b">
                Refinanciamiento <br />
                <span style="font-weight: 300">+ fines generales</span>
              </h4>
              <img src="img/circulo.png" alt="" />
              <p class="b_body">
                ¡Refinancia tu crédito hipotecario con nosotros!
                <strong> y si tu propiedad tiene plusvalía ¡úsala! </strong>
              </p>
            </div>
          </div>
          <!-- CARD -->

          <!-- CARD -->
          <div class="col-lg-3">
            <div class="card-sub text-center">
              <h4 class="tt_b">Libre Disponibilidad $</h4>
              <img src="img/mano.png" alt="" />
              <p class="b_body">
                ¿Tienes una propiedad <br />
                sin deuda?
                <strong>
                  <br />
                  Obtén $ con nosotros <br />
                  para lo que tu quieras.
                </strong>
              </p>
            </div>
            <p class="text-blue">
              * Consultar por esta alternativa con Ejecutivo.
            </p>
          </div>
          <!-- CARD -->
        </div>
        <!-- CARD GRID -->

        <!-- CARD PLAZOS -->
        <div class="row d-flex justify-content-center p-4 mb-4">
          <div id="form"></div>
          <div class="col-lg-3">
            <h5 class="b_plazo">Plazo hasta por</h5>
            <h1 class="b_age">30</h1>
            <h3 class="p_age">Años</h3>
          </div>

          <div class="col-lg-3">
            <h5 class="b_plazo">Financiamos hasta un</h5>
            <h1 class="b_age">80 <span class="d_age">%</span></h1>
            <h3 class="p_age">de tu propiedad</h3>
          </div>
        </div>
        <!-- CARD PLAZOS -->

        <!-- CONTENIDO CENTRAL -->
      </div>
    </div>
    <!-- Cierre Contenedor  -->
  </div>
</template>

<script>
import store from '../../store/index';
import FormCompleto from '../../components/FormCompleto.vue';
import CommonService from '../../service/CommonService';

export default {
  name: 'Home',
  components: {
    FormCompleto,
  },

  created() {
    CommonService.scrollToTop();
    store.commit('changeCreditType', 'home');
  },
};
</script>
