<template>
  <div class="nosotros">
    <div class="container-fluid menu_ajuste bg-nosotros">
      <!-- ===============
BLOQUE HEADER
=====================  -->
      <section id="inicio" class="container p-4 mt-4 text-left">
        <h1 class="tt-prin">#EVOLUCIONA</h1>
        <h1 class="tt-segun">CON NOSOTROS!</h1>

        <p class="tx-general">
          Sumamos una serie de rica y larga experiencia en el negocio <br />
          hipotecario y financiamiento en Chile.
        </p>

        <p class="tx-general">
          Nuestro objetivo ha sido la conformación de una compañía que
          compita<br />
          por maximizar el volumen a través de la mejor oferta en servicio ,
          con<br />
          una atención y asesoría personalizada, y tecnología e innovación en
          nuestros procesos.
        </p>
      </section>
    </div>

    <div class="container juntar">
      <!-- ===============
      BLOQUE CARD GERENTES
      =====================  -->

      <section>
        <h1 class="tt-gerente_e">Gerentes</h1>

        <div class="row d-flex justify-content-center">
          <div class="col-lg-3">
            <ContactCard
              name="Máximo"
              lastname="Silva Irarrázaval"
              phone1="+56 2 3324 1110"
              position="Gerente"
              area="General"
              mail="msi@hipotecariaevoluciona.cl"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Vicente"
              lastname="Padilla Mittermayr"
              phone1="+56 2 3324 1113"
              position="Gerente Comercial"
              area="Inmobiliaria y Empresas"
              mail="vpm@hipotecariaevoluciona.cl"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Joaquín"
              lastname="Ureta Robert"
              phone1="+56 2 3324 1112"
              position="Gerente"
              area="Comercial Residencial"
              mail="jur@hipotecariaevoluciona.cl"
            />
          </div>

          <div></div>

          <div class="col-lg-3">
            <ContactCard
              name="Oriana"
              lastname="Hidalgo Bustamante"
              phone1="+56 2 3324 1111"
              position="Gerente"
              area="Administración y Finanzas"
              mail="ohb@hipotecariaevoluciona.cl"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Patricio"
              lastname="Salinas Herrera"
              phone1="+56 2 3324 1114"
              position="Gerente"
              area="Riesgo"
              mail="psh@hipotecariaevoluciona.cl"
            />
          </div>
        </div>
      </section>

      <!-- ===============
      BLOQUE CARD TRABAJADORES
      =====================  -->

      <section class="mt-4">
        <br />
        <h1 class="tt-gerente-e">Atención Empresas</h1>
        <br />
        <div class="row d-flex justify-content-center">
          <div class="col-lg-3">
            <ContactCard
              name="Catalina"
              lastname="Alert Ramirez"
              phone1="+56 2 3324 1139"
              phone2="+56 9 9319 0996"
              position="Product Manager Senior"
              area="Inmobiliario y Empresas"
              mail="pfm@hipotecariaevoluciona.cl"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Pablo"
              lastname="Flores Martinez"
              phone1="+56 2 3324 1100"
              phone2="+56 9 9640 6057"
              position="Product Manager"
              area="Inmobiliario y Empresas"
              mail="pfm@hipotecariaevoluciona.cl"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Fernando"
              lastname="Mery Dougmac"
              phone1="+56 2 3324 1138"
              phone2="+56 9 4268 4363"
              position="Product Manager Senior"
              area="Inmobiliario y Empresas"
              mail="fmd@hipotecariaevoluciona.cl"
            />
          </div>

          <!--          <div class="col-lg-3">
            <ContactCard
                name="Gonzalo"
                lastname="Smith Amunátegui"
                phone1="+56 9 7608 6744"
                position="Product Manager Senior"
                area="Inmobiliario y Empresas"
                mail="fmd@hipotecariaevoluciona.cl"
            />
          </div>-->
        </div>
      </section>
    </div>
    <!-- Cierre Contenedor  -->
  </div>
</template>

<script>
import ContactCard from '../../components/ContactCard.vue';

export default {
  name: 'Nosotros',
  components: {
    ContactCard,
  },
};
</script>
