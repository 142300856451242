<template>
  <div class="Home">
    <div class="container menu_ajuste p-4">
      <div class="row">
        <h1 :class="[classTitle]">{{ title }}</h1>

        <div v-for="form in forms" :key="form.url" class="col-lg-3 col-sm-12">
          <FormItem :form="form" />
        </div>

        <div class="row justify-content-center mt-4">
          <!-- BOX DESCARGAR  -->
          <div class="col-lg-3 pdins">
            <!-- card  -->

            <a target="black" :href="zip" download>
              <img loading="lazy" src="img/down_s.svg" width="80%" alt="" />
            </a>
          </div>
          <!-- BOX DESCARGAR  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonService from '../../service/CommonService';
import FormItem from '../../components/FormItem.vue';
import { companyForms, homeForms } from '../../data/forms';
import store from '../../store';
import { creditTypes } from '../../data/creditTypes';

const companyZip = 'https://devq.cl/archivos/empresa.zip';
const homeZip = 'https://devq.cl/archivos/hogar.zip';

export default {
  name: 'Formularios',
  components: {
    FormItem,
  },
  data() {
    return {
      forms: Array,
      zip: String,
      title: String,
      classTitle: String,
    };
  },
  created() {
    this.forms =
      store.state.creditType === 'company' ? companyForms : homeForms;
    this.zip = store.state.creditType === 'company' ? companyZip : homeZip;

    if (store.state.creditType === creditTypes.COMPANY) {
      this.title = 'Formularios Empresas - Persona Juridica';
      this.classTitle = 'titular-rosada';
    } else if (store.state.creditType === creditTypes.SUBSIDY) {
      this.title = 'Formularios Persona con Subsidio';
      this.classTitle = 'titular-azul';
    } else {
      this.title = 'Formularios Persona Natural';
      this.classTitle = 'titular-celeste';
    }
    CommonService.scrollToTop();
  },
};
</script>
