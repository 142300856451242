<template>
  <figure
    :class="[fade]"
    class="card-empleado d-flex flex-column justify-content-between w-100 align-items-center"
  >
    <!-- texto interior -->
    <h1 class="tx-nombre">
      {{ name }} <br />
      <span class="tx-nombre-celeste">{{ lastname }}</span>
    </h1>
    <img src="img/line.png" alt="linea" />
    <h2 class="tx-cargo">
      <strong>{{ position }}</strong> <br />
      {{ area }}
    </h2>
    <div class="d-flex justify-content-center">
      <!--<img :src="[imgPhone]" alt="icono telefono" class="m-1 d-none"/>-->
      <img :src="[imgEmail]" alt=" icono email" class="m-1" />
    </div>
    <!-- texto hover-->
    <figcaption class="d-flex flex-column justify-content-center">
      <div class="d-flex flex-column">
        <span class="tx-mail">{{ mail }}</span>
        <!--<span class="tx-fono1-e d-none">{{ phone1 }}</span>-->
        <!--<span class="tx-fono2-e d-none">{{ phone2 }}</span>-->
      </div>

      <div class="d-flex flex-column mt-3">
        <span class="ofi"> Nuestras oficinas: </span>
        <span :class="[adressClass]">{{ address }}</span>
      </div>
    </figcaption>
  </figure>
</template>

<script>
import { creditTypes } from '../data/creditTypes';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    lastname: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
    area: {
      type: String,
      required: true,
    },
    mail: {
      type: String,
      required: true,
    },
    phone1: {
      type: String,
      required: true,
    },
    phone2: {
      type: String,
      default: '',
    },
    address: {
      type: String,
      default: 'Av. Pdte. Riesco 5561, of 1804, Las Condes.',
    },
    figureType: {
      type: String,
      default: 'principal',
    },
  },
  data: function () {
    return {
      creditType: Boolean,
      fade: String,
      imgPhone: String,
      imgEmail: String,
      addressClass: String,
    };
  },
  created() {
    this.creditType = this.figureType !== creditTypes.COMPANY;
    this.fade = this.creditType ? 'imghvr-fade' : 'imghvr-fade2';
    this.imgPhone = this.creditType ? 'img/phone-em.png' : 'img/phone.svg';
    this.imgEmail = this.creditType ? 'img/email-em.png' : 'img/mail.svg';
    this.adressClass = this.creditType ? 'dire' : 'dire-e';
  },
};
</script>

<style></style>
