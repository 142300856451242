<template>
  <div class="Home">
    <div class="container menu_ajuste pt-4 aju_bottom">
      <!-- CONTENIDO  -->
      <div class="row p-4">
        <!-- contenido left -->
        <div class="col-lg-6 text-center">
          <h1 class="tt-prin2 text-center">Te asesoramos</h1>

          <img
            src="img/personas.png"
            class="img-asesoramos text-center"
            alt=""
          />
        </div>
        <!-- contenido left -->

        <!-- contenido right -->
        <div class="col-lg-5">
          <div class="form-contacto row p-4">
            <form method="post" @submit.prevent="contact()">
              <div class="row mb-2">
                <!-- NOMBRE-->
                <div class="col-lg-6 text-left">
                  <label for="nombre"
                    ><span class="txt-formcont">Nombre*</span>
                    <input
                      ref="name"
                      v-model="form.name"
                      type="text"
                      name="nombre"
                      class="form-control form-control-aseramos w-100"
                      required
                    />
                  </label>
                </div>
                <!-- NOMBRE-->
                <!-- APELLIDO-->
                <div class="col-lg-6 text-left">
                  <label for="apellido"
                    ><span class="txt-formcont">Mail*</span>
                    <input
                      ref="email"
                      v-model="form.email"
                      type="mail"
                      name="apellido"
                      class="form-control form-control-aseramos w-100"
                      required
                    />
                  </label>
                </div>
                <!-- APELLIDO-->
              </div>

              <span class="mt-3"></span>

              <!-- TELEFONO-->
              <div class="col-lg-6 text-left mb-2">
                <label for="telefono"
                  ><span class="txt-formcont">Teléfono (Opcional)</span> <br />
                  <input
                    ref="phone"
                    v-model="form.phone"
                    type="text"
                    name="telefono"
                    class="form-control form-control-aseramos w-100"
                  />
                </label>
              </div>
              <!-- TELEFONO-->
              <!-- ESPACIO-->
              <div class="col-lg-6"></div>
              <!-- ESPACIO-->
              <span class="mt-3"></span>
              <!-- SELECCIONAR -->
              <div class="col-lg-12 text-left mb-2">
                <span class="txt-formcont">Tipo de crédito*</span> <br />
                <select
                  ref="creditType"
                  v-model="form.creditType"
                  class="form-control form-control-aseramos w-100"
                  required
                >
                  <option value="1" selected>Subsidio</option>
                  <option value="2">Crédito empresa</option>
                  <option value="3">Crédito hogar</option>
                </select>
              </div>
              <!-- SELECCIONAR -->
              <span class="mt-3"></span>

              <!-- CONSULTAR -->
              <div class="col-lg-12 text-left">
                <span class="txt-formcont">Comentarios</span> <br />
                <textarea
                  id=""
                  ref="commentary"
                  v-model="form.commentary"
                  class="form-control form-control-aseramos w-100"
                  name=""
                  cols="30"
                  rows="10"
                >
                </textarea>
              </div>
              <!-- CONSULTAR -->
              <div class="col-lg-6 text-left mt-4">
                <button class="btn btn-contacto">SOLICITA TU CRÉDITO</button>
              </div>
            </form>
          </div>
        </div>
        <!-- contenido right -->
        <div class="col-lg-1"></div>
      </div>

      <div v-if="alerta == '200'" class="alert alert-primary" role="alert">
        <strong
          >Se envió con éxito el correo, a brevedad uno de nuestros
          especialistas te contactara.</strong
        >
      </div>
    </div>
    <!-- Cierre Contenedor  -->
  </div>
</template>

<script>
import SimulationService from '../../service/rest/Simulation.service';

export default {
  name: 'Home',
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        commentary: '',
        creditType: '',
      },
      alerta: '',
      error: '',
    };
  },
  methods: {
    async contact() {
      // creamos el array con los datos del formulario
      const contactdata = {
        name: this.form.name,
        phone: this.form.phone,
        email: this.form.email,
        commentary: this.form.commentary,
        creditType: this.form.creditType,
      };

      const response = await SimulationService.contact(contactdata);
      this.resultado = response.data;
      this.alerta = response.status;
    },
  },
};
</script>
