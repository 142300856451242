<template>
  <div class="Home">
    <div class="container menu_ajuste p-4">
      <div class="row">
        <h1 class="titular-rosada">Formularios Empresas - Persona Juridica</h1>
        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6 mt-4">
                <p class="p-down">
                  Condiciones <br />
                  Generales <br />Cobranza
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/CondicionesGrlesCobranza.pdf"
                  download
                >
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->
        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6 mt-4">
                <p class="p-down">
                  Declaración <br />
                  Estado Civil
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/DeclaracionEstadoCivil.pdf"
                  download
                >
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->
        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6 mt-4">
                <p class="p-down">
                  Formulario <br />
                  mandatario <br />
                  judicial
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/FormularioMandatarioJudicial.pdf"
                  download
                >
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->
        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6 mt-4">
                <p class="p-down">
                  Formulario <br />
                  tasador
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/FormularioTasador.pdf"
                  download
                >
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->
        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6 mt-4">
                <p class="p-down">
                  Rol Fiador <br />
                  deudor
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a target="black" href="files/empresas/RolFiador.pdf" download>
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->
        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6">
                <p class="p-down">
                  Formulario<br />
                  Solicitud Crédito<br />
                  Hipotecario<br />
                  Empresa
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/FormularioSolicitudCreditoHipotecarioEmpresa.pdf"
                  download
                  ><img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->
        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6">
                <p class="p-down">
                  Formulario<br />
                  Conocimiento<br />
                  Cliente<br />
                  Empresa
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/FormularioConocimientoPersonaJuridica.pdf"
                  download
                >
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->
        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6">
                <p class="p-down">
                  Formularios<br />
                  Simulación<br />
                  Crédito<br />
                  Hipotecario<br />
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/FormularioSimulacionCreditoHipotecario.pdf"
                  download
                >
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->
        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6 mt-4">
                <p class="p-down">Autorización <br />envió email</p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/Autorizacionenviomail.pdf"
                  download
                >
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->
        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6 mt-4">
                <p class="p-down">
                  Formularios<br />
                  Antecedentes
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/FormularioAntecedentes.pdf"
                  download
                >
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->
        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6">
                <p class="p-down">
                  Solicitud<br />
                  Deuda<br />
                  SBIF<br />
                  P-Natural
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/SolicitudDeudaSBIFP-Natural.pdf"
                  download
                >
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->

        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6">
                <p class="p-down">
                  Certificado<br />
                  Protocolos<br />
                  Corporación<br />
                  Internacional
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/Certificadocorporacioninternacional.pdf"
                  download
                >
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->

        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3">
          <!-- card  -->
          <div class="card-down">
            <div class="row">
              <div class="col-lg-6">
                <p class="p-down">
                  Declaracion<br />
                  UAF<br />
                  Persona<br />
                  Juridica
                </p>
              </div>
              <div class="col-lg-6 mt-4">
                <a
                  target="black"
                  href="files/empresas/DeclaracionUAFPersonaJuridica.pdf"
                  download
                >
                  <img src="img/down.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <!-- card  -->
        </div>
        <!-- BOX DESCARGAR  -->

        <!-- BOX DESCARGAR  -->
        <div class="col-lg-3 pdins">
          <!-- card  -->

          <a
            target="black"
            href="https://devq.cl/archivos/empresa.zip"
            download
          >
            <img src="img/down_s.svg" width="80%" alt="" />
          </a>
        </div>
        <!-- BOX DESCARGAR  -->
      </div>
    </div>
    <!-- Cierre Contenedor  -->

    <!-- traemos el Footer  -->
    <FooterEmpresas />
  </div>
</template>

<script>
import CommonService from '../../service/CommonService';

export default {
  name: 'Home',
  created() {
    CommonService.scrollToTop();
  },
};
</script>
