<template>
  <div class="Home">
    <div class="container menu_ajuste">
      <!-- row  -->
      <div class="row d-flex justify-content-center">
        <h2 class="tt-litas p-4">Preguntas frecuentes</h2>
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Qué es un crédito <br />
              Hipotecario?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">¿Qué es un crédito Hipotecario?</h3>
              <p class="text-fag">
                Es un tipo de financiamiento que permite adquirir cualquier tipo
                de activo inmobiliario. También permite el refinanciamiento de
                créditos hipotecarios adquiridos anteriormente, en estos casos
                la propiedad financiada es la garantía del préstamo.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Qué es un Mutuo <br />
              hipotecario endosable?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿Qué es un Mutuo <br />
                hipotecario endosable?
              </h3>
              <p class="text-fag">
                Es un tipo de crédito hipotecario. Se llama Mutuo Hipotecario
                Endosable (M.H.E) porque una vez que se le paga al vendedor, se
                puede ceder el Mutuo (endosar) a otras Instituciones financieras
                autorizadas por la ley, quien se transforma en el acreedor del
                crédito..
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Quiénes pueden tomar <br />
              un Crédito Hipotecario<br />
              con Hipotecaria evoluciona?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿Quiénes pueden tomar <br />
                un Crédito Hipotecario<br />
                con Hipotecaria evoluciona?
              </h3>
              <p class="text-fag">
                Personas Jurídicas y Personas Naturales mayores de 21 años y
                menores de 80 años al vencimiento del crédito.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Cuál es el monto máximo <br />
              que se puede solicitar?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿Cuál es el monto máximo <br />
                que se puede solicitar?
              </h3>
              <p class="text-fag">
                El monto máximo de financiamiento es equivalente al 80% del
                menor valor entre precio de compraventa y tasación.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Cuál es el monto mínimo <br />
              de financiamiento que otorgan?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿Cuál es el monto mínimo <br />
                de financiamiento que otorgan?
              </h3>
              <p class="text-fag">
                El monto mínimo de financiamiento que otorga Hipotecaria
                Evoluciona es de UF 2.000 con un valor mínimo de la propiedad a
                financiar de UF 2.500.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>

          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Hipotecaria Evoluciona otorga <br />
              créditos en cualquier<br />
              parte de Chile?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿Hipotecaria Evoluciona otorga <br />
                créditos en cualquier<br />
                parte de Chile?
              </h3>
              <p class="text-fag">
                Sí. Nuestra compañía puede financiar propiedades a lo largo de
                todo Chile, tanto en sectores urbanos como rurales.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Qué puedes finaciar con un <br />
              Mutuo Hipotecario Endosable?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿Qué puedes finaciar con un <br />
                Mutuo Hipotecario Endosable?
              </h3>
              <p class="text-fag">
                Los recursos se utilizan principalmente para la adquisición,
                construcción, ampliación o reparación de un bien raíz ubicados
                en zonas urbanas y rurales con un destino habitacional, oficinas
                o para fines generales.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Qué es un crédito <br />
              de fines generales?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿Qué es un crédito <br />
                de fines generales?
              </h3>
              <p class="text-fag">
                Además de la adquisición, construcción, ampliación o reparación
                de un bien raíz, los M.H.E de fines generales pueden destinarse
                a fines no acotados, y típicamente están asociados al
                refinanciamiento de otro tipo de deudas, financiamiento de
                proyectos que no tienen un destino habitacional, entre otras
                cosas.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Qué seguros vienen asociados <br />
              al Crédito Hipotecario?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿Qué seguros vienen asociados <br />
                al Crédito Hipotecario?
              </h3>
              <p class="text-fag">
                Todos los créditos requieren tener los siguientes seguros:
                seguro de desgravamen, que extingue la deuda en caso de
                fallecimiento; y seguro de incendio y sismo para la propiedad..
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Cambia el costo de los seguros <br />
              durante la vigencia del crédito?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿Cambia el costo de los seguros <br />
                durante la vigencia del crédito?
              </h3>
              <p class="text-fag">
                Sí, se debe principalmente a la disminución en el costo del
                seguro de desgravamen a medida que el crédito va disminuyendo.
                El valor del seguro de desgravamen se calcula sobre la deuda
                pendiente y no sobre el valor inicial de financiamiento.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Qué son los gastos <br />
              operacionales?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿Qué son los gastos <br />
                operacionales?
              </h3>
              <p class="text-fag">
                Son todos los gastos asociados al otorgamiento del crédito.
                Estos son: tasación de propiedad, estudio de título, redacción
                de escritura, gastos notariales, inscripción en conservador de
                bienes raíces y por ultimo el impuesto al mutuo.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">¿Qué es el CAE?</h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">¿Qué es el CAE?</h3>
              <p class="text-fag">
                La sigla CAE significa “Costo Anual Equivalente”. A la tasa del
                crédito se le suman los seguros de desgravamen, de incendio y
                sismo, y también los gastos operacionales del crédito. De esto
                se obtiene una tasa que es comparable con cualquier oferta de
                otras instituciones.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">¿Qué es Amortización?</h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">¿Qué es Amortización?</h3>
              <p class="text-fag">
                Es el pago del capital en cada cuota durante todo el plazo del
                crédito hasta la extensión de la deuda.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">¿Qué es Dividendo?</h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">¿Qué es Dividendo?</h3>
              <p class="text-fag">
                Es la cuota mensual que se cancela por el crédito hipotecario,
                el que incluye el pago de intereses, amortización y seguros..
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿En qué lugares puedo <br />
              pagar mi dividendo?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿En qué lugares puedo <br />
                pagar mi dividendo?
              </h3>
              <p class="text-fag">
                Puedes cancelar tu dividendo del mes aún vigente en cualquier
                sucursal del Banco BCI o Servipag a lo largo de todo el país,
                presentando la copia del cupón de pago respectivo. También, lo
                puedes hacer electrónicamente, a través de nuestro sitio web
                ingresando a tu sitio privado con tu RUT y clave. .
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              ¿Evoluciona financia operaciones <br />
              con subsidio? ¿Ayuda a la <br />
              obtención de este?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                ¿Evoluciona financia operaciones <br />
                con subsidio? ¿Ayuda a la <br />
                obtención de este?
              </h3>
              <p class="text-fag">
                Si, financiamos la compra de viviendas tanto con o sin subsidio.
                Para la obtención de este, debes realizar el trámite de
                postulación de forma previa a través del Ministerio de Vivienda
                y Urbanismo -> http://beneficios.minvu.gob.cl/. Nuestro
                financiamiento te permite complementar tu subsidio.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              Si soy trabajador dependiente <br />
              ¿Qué documentos principalmente <br />
              debo presentar para ser evaluado?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                Si soy trabajador dependiente <br />
                ¿Qué documentos principalmente <br />
                debo presentar para ser evaluado?
              </h3>
              <p class="text-fag">
                • Últimas 3 liquidaciones de sueldo. Si tienes renta variable,
                debes presentar 6 liquidaciones de renta. • Certificado de AFP
                con últimas 12 cotizaciones. • En el caso de ser funcionario
                público o si no cotizas en una AFP, igualmente podemos
                financiarte. • La antigüedad laboral mínima requerida es de 6
                meses, y la continuidad laboral de 1 año, pero se permiten
                interrupciones acumulativas. • Acreditación de deudas del
                sistema financiero. • Fotocopia de cédula de identidad. •
                Promesa de compra de la vivienda que deseas adquirir.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">
              Soy trabajador independiente <br />
              ¿Qué documentos debo presentar <br />
              para una aprobación de un crédito hipotecario?
            </h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">
                Soy trabajador independiente <br />
                ¿Qué documentos debo presentar <br />
                para una aprobación de un crédito hipotecario?
              </h3>
              <p class="text-fag">
                • Boletas de honorarios de los últimos 6 meses. • Declaración de
                impuesto de últimos 2 años. • Continuidad Laboral de 2 años. •
                Acreditación de deuda del sistema financiero. • Fotocopia de
                cédula de identidad. • Promesa de compra de la vivienda que
                deseas adquirir. • Certificado de afiliación de AFP.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">¿Puedo complementar renta con un Codeudor?</h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">¿Puedo complementar renta con un Codeudor?</h3>
              <p class="text-fag">
                Sí. Tenemos varias opciones. Te recomendamos contactarnos para
                poder asesorarte mejor.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
        <!-- BOX FAQ  -->
        <!-- BOX FAQ  -->
        <div class="col-lg-3">
          <!-- Card Especial Empleado -->
          <figure class="imghvr-fade3 card-fag text-center w-100">
            <!-- texto interior -->
            <h3 class="tx-fag">¿Que es un codeudor?</h3>

            <div class="d-flex justify-content-center">
              <img src="img/Plus-minus.png" alt="icono plus" class="m-1" />
            </div>
            <!-- texto hover-->
            <figcaption>
              <h3 class="tx-fag">¿Que es un codeudor?</h3>
              <p class="text-fag">
                Un codeudor es otra persona que también asume la responsabilidad
                de pagar el préstamo y que puede mejorar tu figura crediticia.
              </p>
              <p class="menos mb-2">-</p>
            </figcaption>
          </figure>
          <!-- Card Especial Empleado -->
        </div>
      </div>
    </div>

    <!-- row  -->

    <!-- traemos el Footer  -->
    <FooterHogar />
  </div>
</template>

<script>
export default {
  name: 'Preguntas',
};
</script>
