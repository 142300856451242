export const homeForms = [
  {
    description: 'Condiciones Generales Cobranza',
    href: 'files/hogar/CondicionesGrlesCobranza.pdf',
  },
  {
    description: 'Declaración Estado Civil',
    href: 'files/hogar/DeclaracionEstadoCivil.pdf',
  },
  {
    description: 'Formulario mandatario judicial',
    href: 'files/hogar/FormularioMandatarioJudicial.pdf',
  },
  {
    description: 'Formulario tasador',
    href: 'files/hogar/FormularioTasador.pdf',
  },
  {
    description: 'Rol Fiador deudor',
    href: 'files/hogar/RolFiador.pdf',
  },
  {
    description: 'Formulario Solicitud Crédito Hipotecario Persona',
    href: 'files/hogar/FormularioSolicitudCreditoHipotecarioPersona.pdf',
  },
  {
    description: 'Formulario Conocimiento Cliente Natural',
    href: 'files/hogar/FormularioConocimientoClienteNatural.pdf',
  },
  {
    description: 'Formularios Simulación Crédito Hipotecario',
    href: 'files/hogar/FormularioSimulacionCreditoHipotecario.pdf',
  },
  {
    description: 'Autorización envió email',
    href: 'files/hogar/Autorizacionenviomail.pdf',
  },
  {
    description: 'Formularios Antecedentes',
    href: 'files/hogar/FormularioAntecedentes.pdf',
  },
  {
    description: 'Solicitud Deuda SBIF P-Natural',
    href: 'files/hogar/SolicitudDeudaSBIFP-Natural.pdf',
  },
  {
    description: 'Formulario Certificado PEP',
    href: 'files/hogar/DeclaracionCertificadoPEP.pdf',
  },
];

export const companyForms = [
  {
    description: 'Condiciones Generales Cobranza',
    href: 'files/empresas/CondicionesGrlesCobranza.pdf',
  },
  {
    description: 'Declaración Estado Civil',
    href: 'files/empresas/DeclaracionEstadoCivil.pdf',
  },
  {
    description: 'Formulario mandatario judicial',
    href: 'files/empresas/FormularioMandatarioJudicial.pdf',
  },
  {
    description: 'Formulario tasador',
    href: 'files/empresas/FormularioTasador.pdf',
  },
  {
    description: 'Rol Fiador deudor',
    href: 'files/empresas/RolFiador.pdf',
  },
  {
    description: 'Formulario Solicitud Crédito Hipotecario Empresa',
    href: 'files/empresas/FormularioSolicitudCreditoHipotecarioEmpresa.pdf',
  },
  {
    description: 'Formulario Conocimiento Cliente Empresa',
    href: 'files/empresas/FormularioConocimientoPersonaJuridica.pdf',
  },
  {
    description: 'Formularios Simulación Crédito Hipotecario',
    href: 'files/empresas/FormularioSimulacionCreditoHipotecario.pdf',
  },
  {
    description: 'Autorización envió email',
    href: 'files/empresas/Autorizacionenviomail.pdf',
  },
  {
    description: 'Formularios Antecedentes',
    href: 'files/empresas/FormularioAntecedentes.pdf',
  },
  {
    description: 'Solicitud Deuda SBIF P-Natural',
    href: 'files/empresas/SolicitudDeudaSBIFP-Natural.pdf',
  },
  {
    description: 'Certificado Protocolos Corporación Internacional',
    href: 'files/empresas/Certificadocorporacioninternacional.pdf',
  },
  {
    description: 'Declaracion UAF Persona Juridica',
    href: 'files/empresas/DeclaracionUAFPersonaJuridica.pdf',
  },
];
