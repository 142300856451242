<template>
  <div class="nosotros">
    <div class="container-fluid bg-nosotros">
      <!-- ===============
BLOQUE HEADER
=====================  -->
      <section id="inicio" class="container p-4 mt-4 text-left">
        <h1 class="tt-prin">#EVOLUCIONA</h1>
        <h1 class="tt-segun">CON NOSOTROS!</h1>

        <div class="col-lg-6">
          <p class="tx-general">
            Sumamos una serie de rica y larga experiencia en el negocio <br />
            hipotecario y financiamiento en Chile.
          </p>
        </div>
        <div class="col-lg-6">
          <p class="tx-general">
            Nuestro objetivo ha sido la conformación de una compañía que compita
            por maximizar el número de clientes cumpliendo sus sueños, a través
            de la mejor oferta en servicio, con una atención y asesoría
            personalizada, y tecnología en nuestros procesos.
          </p>
        </div>
      </section>
    </div>

    <div class="container juntar">
      <!-- ===============
      BLOQUE CARD GERENTES
      =====================  -->

      <section>
        <h1 :class="[managerTitle]">Gerentes</h1>

        <div class="row d-flex justify-content-center">
          <div class="col-lg-3">
            <ContactCard
              name="Máximo"
              lastname="Silva Irarrázaval"
              phone1="+56 2 3324 1110"
              position="Gerente"
              area="General"
              mail="msi@hipotecariaevoluciona.cl"
              :figure-type="creditType"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Vicente"
              lastname="Padilla Mittermayr"
              phone1="+56 2 3324 1113"
              position="Gerente Comercial"
              area="Inmobiliaria y Empresas"
              mail="vpm@hipotecariaevoluciona.cl"
              :figure-type="creditType"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Joaquín"
              lastname="Ureta Robert"
              phone1="+56 2 3324 1112"
              position="Gerente"
              area="Comercial Residencial"
              mail="jur@hipotecariaevoluciona.cl"
              :figure-type="creditType"
            />
          </div>

          <div></div>

          <div class="col-lg-3">
            <ContactCard
              name="Oriana"
              lastname="Hidalgo Bustamante"
              phone1="+56 2 3324 1111"
              position="Gerente"
              area="Administración y Finanzas"
              mail="ohb@hipotecariaevoluciona.cl"
              :figure-type="creditType"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Patricio"
              lastname="Salinas Herrera"
              phone1="+56 2 3324 1114"
              position="Gerente"
              area="Riesgo"
              mail="psh@hipotecariaevoluciona.cl"
              :figure-type="creditType"
            />
          </div>
        </div>
      </section>

      <!-- ===============
      BLOQUE CARD TRABAJADORES
      =====================  -->

      <section v-if="creditType !== creditTypes.COMPANY" class="mt-4">
        <br />
        <h1 class="tt-gerente">Atención Personas</h1>
        <br />
        <div class="row d-flex justify-content-center">
          <div class="col-lg-3">
            <ContactCard
              name="Francisco"
              lastname="Cornejo Urzúa"
              phone1="+56 2 3324 1125"
              phone2="+56 9 7549 2234"
              position="Ejecutivo"
              area="Comercial Residencial"
              mail="fcu@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Sandra"
              lastname="Cifuentes Muñoz"
              phone1="+56 2 3324 1123"
              phone2="+56 9 9331 4181"
              position="Ejecutivo"
              area="Comercial Residencial"
              mail="scm@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Andrea"
              lastname="Pardo Fuenzalida"
              phone1="+56 2 3324 1137"
              phone2="+56 9 9435 8889"
              position="Ejecutivo"
              area="Comercial Residencial"
              mail="apf@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div></div>

          <div class="col-lg-3">
            <ContactCard
              name="Felipe"
              lastname="Ramirez Silva"
              phone1="+56 9 9632 1392"
              position="Ejecutivo"
              area="Comercial Residencial"
              mail="frs@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Catalina"
              lastname="Alert Ramirez"
              phone1="+56 2 3324 1139"
              phone2="+56 9 9319 0996"
              position="Ejecutivo"
              area="Comercial Residencial"
              mail="pfm@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Patricia"
              lastname="Garcia Hidalgo"
              phone1="+56 2 3324 1126"
              phone2="+56 9 9877 5907"
              position="Ejecutivo"
              area="Comercial Subsidio"
              mail="pgh@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div></div>

          <div class="col-lg-3">
            <ContactCard
              name="Denisse"
              lastname="Araya Retamales"
              phone1="+56 "
              position="Ejecutivo"
              area="Comercial Subsidio"
              mail="dar@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Damyan"
              lastname="Martinez Yañez"
              phone1="+56 "
              position="Ejecutivo"
              area="Comercial Subsidio"
              mail="dmy@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Paul"
              lastname="Urra Pérez"
              phone1="+56 "
              position="Ejecutivo"
              area="Comercial Subsidio"
              mail="pup@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div></div>

          <div class="col-lg-3">
            <ContactCard
              name="Vicente"
              lastname="Sepulveda Caru"
              phone1="+56 "
              position="Ejecutivo"
              area="Comercial Subsidio"
              mail="vsc@hiptecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Gabriela"
              lastname="Cancino Contreras"
              phone1="+56 "
              position="Ejecutiva"
              area="Comercial Subsidio"
              mail="gcc@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Matías"
              lastname="Leiva Barraza"
              phone1="+56 "
              position="Ejecutivo"
              area="Comercial Subsidio"
              mail="mlb@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div></div>

          <div class="col-lg-3">
            <ContactCard
              name="Roxana"
              lastname="Briones Mejías"
              phone1="+56 "
              position="Ejecutiva"
              area="Comercial Subsidio"
              mail="rbm@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="Santiago"
              lastname="Carreño Saldaña"
              phone1="+56 "
              position="Ejecutivo"
              area="Comercial Subsidio"
              mail="scs@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div class="col-lg-3">
            <ContactCard
              name="María Jose"
              lastname="Inzunza Suazo"
              phone1="+56 "
              position="Ejecutiva"
              area="Comercial Subsidio"
              mail="mis@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.HOME"
            />
          </div>

          <div></div>
        </div>
      </section>

      <!-- ===============
      BLOQUE CARD TRABAJADORES
      =====================  -->

      <section
        v-if="
          creditType === creditTypes.COMPANY ||
          creditType === creditTypes.PRINCIPAL
        "
        class="mt-4"
      >
        <br />
        <h1 class="tt-gerente-e">Atención Empresas</h1>
        <br />
        <div class="row d-flex justify-content-center">
          <div class="col-lg-3">
            <ContactCard
              name="Fernando"
              lastname="Mery Dougmac"
              phone1="+56 2 3324 1138"
              phone2="+56 9 4268 4363"
              position="Product Manager Senior"
              area="Inmobiliario y Empresas"
              mail="fmd@hipotecariaevoluciona.cl"
              :figure-type="creditTypes.COMPANY"
            />
          </div>
        </div>
      </section>
    </div>
    <!-- Cierre Contenedor  -->
  </div>
</template>

<script>
// Componentes Globales
import CommonService from '../service/CommonService';
import ContactCard from '../components/ContactCard.vue';
import store from '../store';
import { creditTypes } from '../data/creditTypes';

export default {
  name: 'NosotrosHome',
  components: {
    ContactCard,
  },
  data() {
    return {
      creditType: store.state.creditType,
      creditTypes,
      managerTitle: String,
    };
  },
  created() {
    this.managerTitle =
      this.creditType === creditTypes.COMPANY ? 'tt-gerente_e' : 'tt-gerente';
    CommonService.scrollToTop();
  },
};
</script>
