<template>
  <div class="card-down">
    <div class="row">
      <div class="col-lg-6 mt-4">
        <p class="p-down">
          {{ form.description }}
        </p>
      </div>
      <div class="col-lg-6 mt-4">
        <a target="black" :href="form.href" download>
          <img loading="lazy" src="img/down.svg" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormItem',
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
